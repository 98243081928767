<template>
  <div class="q-ma-sm">
    <div class="row q-col-gutter-sm">
      <div class="col-6" :class="{ 'col-lg-9': !showCharts }">
        <div class="q-gutter-y-sm">
          <div>
            <div
              class="text-h3 primary sub_header"
              v-if="documentInfo.moduleName === 'PQ'"
            >
              {{ getRes("Form.Field.PQReference") }}:
            </div>
            <div class="text-h3 primary sub_header" v-else>
              {{ getRes("Form.Field.EOIReference") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ documentInfo.ref }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary sub_header">
              {{
                documentInfo.moduleName === "PQ"
                  ? getRes("Form.Field.PQType")
                  : getRes("Form.Field.EOIType")
              }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ getTenderType(documentInfo.tenderTypeMethod) }}
            </div>
          </div>
          <div v-if="BudgetHolderDepartment !== ''">
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.BudgetHolderDepartment") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ BudgetHolderDepartment }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.SubjectTitle") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ documentInfo.subjEng }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.Description") }}:
            </div>
            <div
              class="text-h6 text-grey-9 context"
              style="white-space: pre-wrap"
              v-text="documentInfo.details"
            ></div>
          </div>
          <div>
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.Contact") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              <div>
                {{ getRes("Form.Field.Name") }}:
                {{ documentInfo.contactPersonName }}
              </div>
              <div>
                {{ getRes("Form.Field.TelNumber") }}:
                {{ documentInfo.contactPersonTelNumber }}
              </div>
              <div>
                {{ getRes("Form.Field.Email") }}:
                {{ documentInfo.contactPersonEmailAddress }}
              </div>
            </div>
          </div>
          <div v-if="documentInfo.issueDate !== null">
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.InvitationDate") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ formatDate(documentInfo.issueDate) }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary sub_header">
              {{ getRes("Form.Field.ClosingDateandTime") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ formatDate(documentInfo.closingDate) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" :class="{ 'col-lg-3': !showCharts }">
        <div class="row q-col-gutter-sm">
          <div class="col-12 col-lg-6" v-if="showCharts">
            <div class="q-gutter-y-sm">
              <pro-eoi-tender-box-chart
                :tenderId="tenderId"
                :numTenderers.sync="numTenderer"
                :moduleName="documentInfo.moduleName"
              />
              <pro-nda-chart :tenderId="tenderId" />
            </div>
          </div>
          <div class="col">
            <pro-checkpoint-chart :tenderId="tenderId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProEoiTenderBoxChart from "@/components/PROSmart/Dashboard/ProEoiTenderBoxChart";
import ProNdaChart from "@/components/PROSmart/Dashboard/ProNdaChart";
import ProCheckpointChart from "@/components/PROSmart/Dashboard/ProCheckpointChart";

export default {
  name: "BuyerEoiDashboard",
  components: { ProNdaChart, ProEoiTenderBoxChart, ProCheckpointChart },
  props: {
    documentInfo: Object,
    documentStatus: Number,
  },
  data() {
    return {
      tenderId: "",
      BudgetHolderDepartment: "",
      numTenderer: null,
      showDashboardStatus: [50, 80, 90],
    };
  },
  computed: {
    showCharts() {
      return this.showDashboardStatus.includes(this.documentStatus);
    },
  },
  created() {
    this.tenderId = parseInt(this.$route.params.id);
  },
  methods: {
    getTenderType(tenderType) {
      switch (tenderType) {
        case "Open":
          return this.getRes("Form.Option.Open");
        case "SelectiveOrRestricted":
          return this.getRes("Form.Option.SelectiveRestricted");
        case "OpenAndSelective":
          return this.getRes("Form.Option.OpenSelective");
        case "SingleAndRestricted":
          return this.getRes("Form.Option.SingleAndRestricted");
      }
    },
    formatDate(date) {
      return date === null
        ? ""
        : this.$proSmart.common.getFormattedDate(new Date(date));
    },
  },
};
</script>

<style scoped>
.context {
  padding: 10px 0;
  font-size: 18px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.9);
}
.sub_header {
  text-decoration-line: underline;
  font-size: 16px;
  font-weight: 700;
}
</style>
