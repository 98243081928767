<template>
  <div class="q-ma-sm">
    <div class="row q-col-gutter-sm">
      <div class="col-12">
        <div class="q-gutter-y-sm">
          <div>
            <div
              class="text-h3 primary"
              v-if="documentInfo.moduleName === 'RFQ'"
            >
              {{ getRes("Document.RfqRef") }}:
            </div>
            <div class="text-h3 primary" v-else>
              {{ getRes("Document.TenderRef") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ documentInfo.ref }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary">
              {{ getRes("Form.Field.TenderType") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ getTenderType(documentInfo.tenderTypeMethod) }}
            </div>
          </div>
          <div v-if="BudgetHolderDepartment !== ''">
            <div class="text-h3 primary">
              {{ getRes("Form.Field.BudgetHolderDepartment") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ BudgetHolderDepartment }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary">
              {{ getRes("Form.Field.SubjectTitle") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ documentInfo.subjEng }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary">
              {{ getRes("Form.Field.Description") }}:
            </div>
            <div
              class="text-h6 text-grey-9 context"
              style="white-space: pre-wrap"
              v-text="documentInfo.details"
            ></div>
          </div>
          <div>
            <div class="text-h3 primary">
              {{ getRes("Form.Field.Contact") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              <div>
                {{ getRes("Form.Field.Name") }}:
                {{ documentInfo.contactPersonName }}
              </div>
              <div>
                {{ getRes("Form.Field.TelNumber") }}:
                {{ documentInfo.contactPersonTelNumber }}
              </div>
              <div>
                {{ getRes("Form.Field.Email") }}:
                {{ documentInfo.contactPersonEmailAddress }}
              </div>
            </div>
          </div>
          <div v-if="documentInfo.issueDate !== null">
            <div class="text-h3 primary">
              {{ getRes("Form.Field.InvitationDate") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ formatDate(documentInfo.issueDate) }}
            </div>
          </div>
          <div>
            <div class="text-h3 primary">
              {{ getRes("Form.Field.ClosingDateandTime") }}:
            </div>
            <div class="text-h6 text-grey-9 context">
              {{ formatDate(documentInfo.closingDate) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackupBuyerTenderDashboard",
  props: {
    documentInfo: Object,
    documentStatus: Number,
  },
  data() {
    return {
      tenderId: "",
      BudgetHolderDepartment: "",
    };
  },
  created() {
    this.tenderId = parseInt(this.$route.params.id);
  },
  methods: {
    formatDate(date) {
      return date === null
        ? ""
        : this.$proSmart.common.getFormattedDate(new Date(date));
    },
    getTenderType(tenderType) {
      switch (tenderType) {
        case "Open":
          return this.getRes("Form.Option.Open");
        case "SelectiveOrRestricted":
          return this.getRes("Form.Option.SelectiveRestricted");
        case "OpenAndSelective":
          return this.getRes("Form.Option.OpenSelective");
        case "SingleAndRestricted":
          return this.getRes("Form.Option.SingleAndRestricted");
      }
    },
  },
};
</script>

<style scoped>
.context {
  padding: 10px 0;
  font-size: 18px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.9);
}
</style>
