<template>
  <q-card>
    <q-card-section>
      <div class="text-h6 text-grey-6">
        {{ getRes("Form.Section.SupplierPerformance") }}
      </div>
      <div style="height: 120px"><canvas ref="chart"></canvas></div>
      <div class="row justify-evenly">
        <span
          ><q-badge rounded color="completed" class="q-mr-sm" />{{
            moduleName === "PQ"
              ? getRes("System.OfferType.Replied")
              : getRes("Form.Section.ExpressedInterest")
          }}</span
        >
        <span
          ><q-badge rounded color="warning" class="q-mr-sm" />{{
            getRes("System.OfferType.NotInterest")
          }}</span
        >
        <span
          ><q-badge rounded color="error" class="q-mr-sm" />{{
            getRes("System.OfferType.NoResponse")
          }}</span
        >
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Chart from "chart.js/auto";
import loadColors from "@/colors";

export default {
  name: "ProEoiTenderBoxChart",
  props: {
    tenderId: {
      type: Number,
      required: true,
    },
    numTenderers: Number,
    moduleName: String,
  },
  data() {
    return {
      chart: null,
      colors: {},
      tenderers: null,
      replySlip: null,
      interest: null,
      notInterest: null,
      noResponse: null,
      siteVisit: null,
    };
  },
  methods: {
    loadChart() {
      let ctx = this.$refs.chart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            this.getRes("Form.Section.ReplySlip"),
            this.getRes("Form.Section.RegisterSiteVisit"),
            this.getRes("Form.Section.Offers"),
          ],
          datasets: [
            {
              label: this.getRes("Form.Section.ReplySlip"),
              data: [this.replySlip, 0, 0],
              backgroundColor: [this.colors.edit],
            },
            {
              label: this.getRes("Form.Section.RegisterSiteVisit"),
              data: [0, this.siteVisit, 0],
              backgroundColor: [this.colors.edit],
            },
            {
              label:
                this.moduleName === "PQ"
                  ? this.getRes("System.OfferType.Replied")
                  : this.getRes("Form.Section.ExpressedInterest"),
              data: [0, 0, this.interest],
              backgroundColor: [this.colors.completed],
            },
            {
              label: this.getRes("System.OfferType.NotInterest"),
              data: [0, 0, this.notInterest],
              backgroundColor: [this.colors.warning],
            },
            {
              label: this.getRes("System.OfferType.NoResponse"),
              data: [0, 0, this.noResponse],
              backgroundColor: [this.colors.error],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          barPercentage: 0.7,
          indexAxis: "y",
          scales: {
            x: {
              stacked: true,
              max: Math.max(
                this.interest + this.notInterest + this.noResponse,
                this.tenderers
              ),
              ticks: {
                precision: 0,
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    },
  },
  created() {
    this.colors = loadColors("edit", "warning", "completed", "error");
  },
  mounted() {
    Promise.all([
      this.$proSmart.dashboard.getETenderBox(this, this.tenderId),
      this.$proSmart.dashboard.getSiteVisit(this, this.tenderId),
    ]).then(([eTenderBox, siteVisit]) => {
      this.tenderers = eTenderBox.totalTenderer;
      this.replySlip = eTenderBox.replySlip;
      this.submitInterest = eTenderBox.submitInterest;
      this.interest = eTenderBox.offers.Interest;
      this.notInterest = eTenderBox.offers.NotInterest;
      this.noResponse = eTenderBox.offers.NoResponse;
      this.siteVisit = siteVisit.count;
      this.$emit("update:numTenderers", this.tenderers);

      this.loadChart();
    });
  },
};
</script>
