<template>
  <q-card>
    <q-card-section>
      <div class="row">
        <q-icon
          name="PROSmart-ClosingDate"
          color="primary"
          size="lg"
          left
          class="col-auto self-center"
        />
        <div class="col">
          <div class="text-h6 text-grey-6">
            {{ getRes("Document.ClosingDate") }}
          </div>
          <div class="text-h5">
            {{
              closingDate
                ? $proSmart.common.format.dateTime(new Date(closingDate))
                : ""
            }}
          </div>
        </div>
        <div class="col-auto self-center">{{ timeTilClosing }}</div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ProClosingDate",
  props: {
    closingDate: String,
  },
  computed: {
    timeTilClosing() {
      if (!this.closingDate) return "";

      const diff = new Date(this.closingDate) - new Date();
      const days = Math.floor(diff / (24 * 3600 * 1000));
      const leave = diff % (24 * 3600 * 1000);
      const hours = Math.floor(leave / (3600 * 1000));
      const minutes = Math.floor((leave % (3600 * 1000)) / (60 * 1000));

      if (days > 0) {
        return `${days} days left`;
      } else if (days == 0) {
        if (new Date(this.closingDate).getDate() == new Date().getDate()) {
          return `Closing today after ${hours} hours ${minutes} minutes`;
        }
        return `${hours} hours left`;
      } else {
        return `Closed`;
      }
    },
  },
};
</script>
