<script>
import TendererTenderDashboard from "@/components/PROSmart/Dashboard/TendererTenderDashboard";
import TendererEoiDashboard from "@/components/PROSmart/Dashboard/TendererEoiDashboard";
import ProSmartTechnicalAssessorDashboard from "@/components/PROSmart/Dashboard/ProSmartTechnicalAssessorDashboard";
import BuyerTenderDashboard from "@/components/PROSmart/Dashboard/BuyerTenderDashboard";
import BuyerEoiDashboard from "@/components/PROSmart/Dashboard/BuyerEoiDashboard";
import BackupBuyerTenderDashboard from "@/components/PROSmart/Dashboard/BackupBuyerTenderDashboard";
import ProStepperMixin from "@/views/mixins/ProStepperMixin";

export default {
  name: "documentDashboard",
  inheritAttrs: false,
  mixins: [ProStepperMixin],
  data() {
    return {
      role: this.$route.params.userRole,
      key: "",
      ref: this.$route.params.ref,
      status: this.$route.params.status,
      mapping: {
        tenderBuyer: BuyerTenderDashboard,
        eoiBuyer: BuyerEoiDashboard,
        tenderTenderer: TendererTenderDashboard,
        eoiTenderer: TendererEoiDashboard,
        technicalAssessor: ProSmartTechnicalAssessorDashboard,
        tenderBackupBuyer: BackupBuyerTenderDashboard,
      },
    };
  },
  methods: {
    loadStepperCompleted(key) {
      this.key = key;
      if (key === null) {
        //redirect to stepper
        const stepperList = JSON.parse(
          sessionStorage.getItem("stepperList") || "[]"
        );
        const stepper = stepperList.find((s) => this.getStepperAction(s).route);
        if (stepper) this.$router.replace(this.getStepperAction(stepper).route);
      }
    },
  },
  render(h) {
    return h("div", {}, [h(this.mapping[this.key], { props: this.$attrs })]);
  },
};
</script>
