<template>
  <div class="q-ma-sm">
    <div class="row q-col-gutter-sm">
      <div class="q-gutter-y-sm col-7">
        <div>
          <div class="text-h3 primary" v-if="documentInfo.moduleName === 'RFQ'">
            {{ getRes("Document.RfqRef") }}:
          </div>
          <div class="text-h3 primary" v-if="documentInfo.moduleName === 'RFI'">
            {{ getRes("Document.RfiRef") }}:
          </div>
          <div class="text-h3 primary" v-else>
            {{ getRes("Document.TenderRef") }}:
          </div>
          <div class="text-h6 text-grey-9 context">
            {{ documentInfo.ref }}
          </div>
        </div>
        <div>
          <div class="text-h3 primary">
            {{ getRes("Form.Field.SubjectTitle") }}:
          </div>
          <div class="text-h6 text-grey-9 context">
            {{ documentInfo.subjEng }}
          </div>
        </div>
        <div>
          <div class="text-h3 primary">
            {{ getRes("Form.Field.Description") }}:
          </div>
          <div
            class="text-h6 text-grey-9 context"
            style="white-space: pre-wrap"
            v-text="documentInfo.details"
          ></div>
        </div>
        <div>
          <div class="text-h3 primary">{{ getRes("Form.Field.Contact") }}:</div>
          <div class="text-h6 text-grey-9 context">
            <div>
              {{ getRes("Form.Field.Name") }}:
              {{ documentInfo.contactPersonName }}
            </div>
            <div>
              {{ getRes("Form.Field.TelNumber") }}:
              {{ documentInfo.contactPersonTelNumber }}
            </div>
            <div>
              {{ getRes("Form.Field.Email") }}:
              {{ documentInfo.contactPersonEmailAddress }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="row q-col-gutter-sm">
          <div class="col-12">
            <pro-closing-date :closingDate="documentInfo.closingDate" />
          </div>
          <div class="col-12">
            <pro-all-action-menu />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProClosingDate from "@/components/PROSmart/Dashboard/ProClosingDate";
import ProAllActionMenu from "@/components/PROSmart/Dashboard/ProAllActionMenu";

export default {
  name: "TendererTenderDashboard",
  components: { ProClosingDate, ProAllActionMenu },
  props: {
    documentInfo: Object,
  },
  data() {
    return {
      tenderId: "",
    };
  },
  created() {
    this.tenderId = this.$route.params.id;
  },
  methods: {
    formatDetail(value) {
      return value
        ? value.replace(/\r\n/g, "<br/>").replace(/\n/g, "<br/>")
        : "";
    },
    formatDate(date) {
      return date === null
        ? ""
        : this.$proSmart.common.getFormattedDate(new Date(date));
    },
  },
};
</script>

<style scoped>
.context {
  padding: 10px 0;
  font-size: 18px;
  line-height: 2;
  color: rgba(0, 0, 0, 0.9);
}
</style>
