<template>
  <pro-action-menu-list :action-menus="actionMenus" :loading="isLoading" />
</template>

<script>
import ProActionMenuList from "@/components/PROSmart/ActionMenu/ProActionMenuList";

export default {
  name: "ProAllActionMenu",
  components: { ProActionMenuList },
  data() {
    return {
      isLoading: false,
      actionMenus: [],
    };
  },
  created() {
    const stepperList = JSON.parse(
      sessionStorage.getItem("stepperList") || "[]"
    );

    if (stepperList.length) {
      //load action menu
      this.isLoading = true;

      const stepperIds = stepperList
        .flatMap((stepper) => [stepper, ...stepper.childrenStepperCode])
        .map((stepper) => stepper.id);

      this.$proSmart.tender
        .getAllActionMenu(this, this.$route.params.id, stepperIds)
        .then((resp) => {
          //consolidate menu groups with same description
          this.actionMenus = resp.reduce((arr, currentGroup) => {
            const findGroup = arr.find(
              (group) => group.descriptionRkey === currentGroup.descriptionRkey
            );
            if (findGroup) {
              findGroup.menuItems = findGroup.menuItems.concat(
                currentGroup.menuItems
              );
            } else {
              arr.push(currentGroup);
            }
            return arr;
          }, []);
        })
        .catch((err) => {
          this.actionMenus = [];
          console.error(err);
          this.$alert(
            this.getRes("ErrorMessage.LoadActionMenuError"),
            this.getRes("WorkflowOperation.Msg_failure1"),
            { confirmButtonText: this.getRes("ok") }
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
};
</script>
