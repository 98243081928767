<template>
  <q-card>
    <q-card-section>
      <div class="text-h6 text-grey-6">
        {{ getRes("Form.Section.ETenderBox") }}
      </div>
      <div class="row justify-evenly">
        <span>
          <q-badge rounded color="completed" class="q-mr-sm" />
          {{ getRes("System.OfferType.Offer") }}
        </span>
        <span>
          <q-badge rounded color="warning" class="q-mr-sm" />
          {{ getRes("System.OfferType.NoOffer") }}
        </span>
        <span>
          <q-badge rounded color="error" class="q-mr-sm" />
          {{ getRes("System.OfferType.NoResponse") }}
        </span>
      </div>
      <div style="height: 120px">
        <canvas ref="chart"></canvas>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Chart from "chart.js/auto";
import loadColors from "@/colors";

export default {
  name: "ProTenderBoxChart",
  props: {
    tenderId: {
      type: Number,
      required: true,
    },
    numTenderers: Number,
  },
  data() {
    return {
      chart: null,
      colors: {},
      tenderers: null,
      replySlip: null,
      submitInterest: null,
      offer: null,
      noOffer: null,
      noResponse: null,
    };
  },
  methods: {
    loadChart() {
      let ctx = this.$refs.chart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            this.getRes("Form.Section.ReplySlip"),
            this.getRes("Form.Section.SubmitInterest"),
            this.getRes("Form.Section.Offers"),
          ],
          datasets: [
            {
              label: this.getRes("Form.Section.ReplySlip"),
              data: [this.replySlip, 0, 0],
              backgroundColor: [this.colors.edit],
            },
            {
              label: this.getRes("Form.Section.SubmitInterest"),
              data: [0, this.submitInterest, 0],
              backgroundColor: [this.colors.edit],
            },
            {
              label: this.getRes("Form.Section.Offers"),
              data: [0, 0, this.offer],
              backgroundColor: [this.colors.completed],
            },
            {
              label: this.getRes("Form.Section.SubNoOff"),
              data: [0, 0, this.noOffer],
              backgroundColor: [this.colors.warning],
            },
            {
              label: this.getRes("System.OfferType.NoResponse"),
              data: [0, 0, this.noResponse],
              backgroundColor: [this.colors.error],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          barPercentage: 0.7,
          indexAxis: "y",
          scales: {
            x: {
              stacked: true,
              max: Math.max(
                this.offer + this.noOffer + this.noResponse,
                this.tenderers
              ),
              ticks: {
                precision: 0,
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    },
  },
  created() {
    this.colors = loadColors("edit", "warning", "completed", "error");
  },
  mounted() {
    this.$proSmart.dashboard.getETenderBox(this, this.tenderId).then((msg) => {
      this.tenderers = msg.totalTenderer;
      this.replySlip = msg.replySlip;
      this.submitInterest = msg.submitInterest;
      this.offer = msg.offers.New;
      this.noOffer = msg.offers.NoOffer;
      this.noResponse = msg.offers.NoResponse + msg.offers.Withdraw;
      this.$emit("update:numTenderers", this.tenderers);

      this.loadChart();
    });
  },
};
</script>
