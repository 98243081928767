<template>
  <q-card>
    <q-card-section>
      <div class="row">
        <q-icon
          name="PROSmart-ClosingDate"
          color="primary"
          size="lg"
          left
          class="col-auto self-center"
        />
        <div class="col">
          <div class="text-h6 text-grey-6">
            {{ $t("Document.Deadline") }}
          </div>
          <div class="text-h5">
            {{
              deadline
                ? $proSmart.common.getFormattedDate(new Date(deadline))
                : ""
            }}
          </div>
        </div>
        <div class="col-auto self-center">{{ timeTilClosing }}</div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ProDeadline",
  props: {
    deadline: String,
  },
  computed: {
    timeTilClosing() {
      if (!this.deadline) return "";

      const days = Math.floor(
        (new Date() - new Date(this.deadline)) / (24 * 3600 * 1000)
      );

      if (days > 0) {
        return this.$t("Document.Deadline.Passed");
      } else if (days == 0) {
        return this.$t("Document.Deadline.Today");
      } else {
        return this.$t("Document.Deadline.DaysLeft", { days: -days });
      }
    },
  },
};
</script>
