<template>
  <q-card>
    <q-card-section>
      <div class="q-gutter-sm">
        <div class="text-h6 text-grey-6">{{ getRes("Form.Section.NDA") }}</div>
        <div class="q-px-sm">
          <canvas ref="chart"></canvas>
        </div>
        <q-card flat bordered>
          <q-card-section class="q-py-sm">
            <div class="row">
              <div class="col">
                <q-badge rounded color="error" class="q-mr-sm" />{{
                  getRes("CustMenu.Col.NoSubmitted")
                }}
              </div>
              <div class="col-auto">{{ notSubmitted }}</div>
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered>
          <q-card-section class="q-py-sm">
            <div class="row">
              <div class="col">
                <q-badge rounded color="completed" class="q-mr-sm" />{{
                  getRes("CustMenu.Col.Submitted")
                }}
              </div>
              <div class="col-auto">{{ submitted }}</div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Chart from "chart.js/auto";
import loadColors from "@/colors";

export default {
  name: "ProDocumentNdaChart",
  props: {
    tenderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
      colors: {},
      submitted: null,
      notSubmitted: null,
    };
  },
  methods: {
    loadChart() {
      if (!this.$refs.chart) return;

      let ctx = this.$refs.chart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: [
            this.getRes("CustMenu.Col.NoSubmitted"),
            this.getRes("CustMenu.Col.Submitted"),
          ],
          datasets: [
            {
              data: [this.notSubmitted, this.submitted],
              backgroundColor: [this.colors.error, this.colors.completed],
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    },
  },
  created() {
    this.colors = loadColors("completed", "error");
  },
  mounted() {
    this.$proSmart.dashboard
      .getNdaStatus(this, this.tenderId)
      .then((ndaStatus) => {
        this.submitted = ndaStatus.submitted;
        this.notSubmitted = ndaStatus.notSubmitted;
        this.loadChart();
      });
  },
};
</script>
