<template>
  <q-card>
    <q-card-section class="column">
      <div class="col text-h6 text-grey-6">
        {{ $t("Form.Section.Process") }}
      </div>
      <q-stepper
        class="col self-start full-width"
        style="padding-bottom: 0"
        value="0"
        vertical
        inactive-color="grey"
        done-color="completed"
        flat
        header-nav
      >
        <q-step
          v-for="checkpoint in checkpointList"
          :key="checkpoint.sequence"
          :name="checkpoint.sequence"
          :title="$t(checkpoint.description)"
          :done="checkpoint.isPassed"
          :header-nav="!!checkpoint.to"
          @click="handleCheckpointClick(checkpoint)"
        />
      </q-stepper>
    </q-card-section>
  </q-card>
</template>

<script>
import ProStepperMixin from "@/views/mixins/ProStepperMixin";

export default {
  name: "ProCheckpointChart",
  mixins: [ProStepperMixin],
  props: {
    tenderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      checkpointList: [],
    };
  },
  methods: {
    findInStepper(steppers, workflowCode) {
      const stepper = steppers.find(
        (s) => s.editWorkflowProcessCode === workflowCode
      );
      return stepper ? this.getEditAction(stepper).route : null;
    },
    findInActionMenu(actionMenuItems, workflowCode) {
      console.log("workflowCode", workflowCode);
      const actionMenuItem = actionMenuItems.find(
        (a) => a.editWorkflowCode === workflowCode
      );
      return actionMenuItem ? this.getEditAction(actionMenuItem).route : null;
    },
    handleCheckpointClick(checkpoint) {
      if (checkpoint.to) this.$router.push(checkpoint.to);
    },
  },
  created() {
    const steppers = JSON.parse(
      sessionStorage.getItem("stepperList") || "[]"
    ).flatMap((stepper) => [stepper, ...stepper.childrenStepperCode]);
    const actionMenuItems = JSON.parse(
      sessionStorage.getItem("actionMenuList") || "[]"
    ).flatMap((group) => group.menuItems);

    this.$proSmart.dashboard
      .getDocumentCheckpoint(this, this.tenderId)
      .then((list) => {
        this.checkpointList = list.map((c) => ({
          ...c,
          to: c.isPassed
            ? null
            : this.findInStepper(steppers, c.workflowCode) ||
              this.findInActionMenu(actionMenuItems, c.workflowCode),
        }));
      });
  },
};
</script>
