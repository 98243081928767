<template>
  <div class="row">
    <div class="col-7">{{ getRes('Form.Section.ProSmartTechnicalAssessorDashboard') }}</div>
    <div class="col-5">{{ getRes('Form.Section.ProSmartTechnicalAssessorDashboard') }}</div>
  </div>

</template>

<script>
export default {};
</script>
